import * as React from 'react';
import {useState} from 'react';
import {useAppSelector} from '../../hooks';
import LessonsRow from '../lessons-row/lessonsRow';
import {Lesson, ScheduleClass, ScheduleTab} from '../../types/schedule';
import ScheduleTabs from './scheduleTabs';

type Props = {
    scheduleByDay: ScheduleClass | null
};

function ScheduleTable({scheduleByDay}: Props): JSX.Element{
    const getLessonPairsByDay = () => {
        const firstArray = scheduleByDay![806] ?? [];
        const secondArray = scheduleByDay![908] ?? [];
        const thirdArray = scheduleByDay![708] ?? [];
        
        const maxLessons = Math.max(firstArray?.length ?? 0, secondArray?.length ?? 0, thirdArray?.length ?? 0);
        let arr = new Array<Lesson[]>();
        for (let i = 0; i < maxLessons; i += 1) {
            arr.push([firstArray[i] ?? null, secondArray[i] ?? null, thirdArray[i] ?? null])
        }
        return arr;
    }

    return (
        <table className="schedule__table_day">
            <thead>
            <tr className="schedule__table_day-th-all">
                <th className="schedule__table_day-th">806</th>
                <th className="schedule__table_day-th">908</th>
                <th className="schedule__table_day-th">708</th>
            </tr>
            </thead>
            <tbody>
            { scheduleByDay
                ? getLessonPairsByDay().map((x: (Lesson|null)[], index: number) =>
                    <LessonsRow lessons={x} key={index}/>)
                : <LessonsRow lessons={[]}/>
            }
            </tbody>
        </table>
    );
}

function Schedule(): JSX.Element{
    const schedule = useAppSelector((state) => state.schedule);
    const [scheduleTab, setScheduleTab] = useState<ScheduleTab>(ScheduleTab.Monday);

    return (
        <section className="schedule__wrapper">
            <ScheduleTabs
                currentTab={scheduleTab}
                updateTab={(tabName: ScheduleTab) => {
                    setScheduleTab(tabName);
                }}
            />
            {scheduleTab === ScheduleTab.Monday && <ScheduleTable scheduleByDay={schedule?.monday ?? null} />}
            {scheduleTab === ScheduleTab.Tuesday && <ScheduleTable scheduleByDay={schedule?.tuesday ?? null} />}
            {scheduleTab === ScheduleTab.Wednesday && <ScheduleTable scheduleByDay={schedule?.wednesday ?? null} />}
            {scheduleTab === ScheduleTab.Thursday && <ScheduleTable scheduleByDay={schedule?.thursday ?? null} />}
            {scheduleTab === ScheduleTab.Friday && <ScheduleTable scheduleByDay={schedule?.friday ?? null} />}
            {scheduleTab === ScheduleTab.Saturday && <ScheduleTable scheduleByDay={schedule?.saturday ?? null} />}
            {scheduleTab === ScheduleTab.Sunday && <ScheduleTable scheduleByDay={schedule?.sunday ?? null} />}
        </section>
    );
}

export default Schedule;